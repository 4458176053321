import React, { Component, Suspense } from 'react';
import Router from './shared/Router';
import Spinner from 'react-bootstrap/Spinner';

import './vendor/styles/bootstrap.scss'
import './vendor/styles/appwork.scss'
//import './vendor/styles/theme-vibrant.scss' 
import './vendor/styles/theme-travelthru.scss'
import './vendor/styles/colors.scss'
import './vendor/styles/uikit.scss'
import './vendor/styles/themes/travelthru.scss'
import './App.scss'
import i18n from './i18n.js'

import moment from 'moment';
import 'moment/min/locales';

import { ThemeProvider } from './ThemeContext';
import { APIProvider } from "@vis.gl/react-google-maps";
import GoogleMapsLoader from './components/ui/googleMaps/GoogleMapsLoader';
import { getGoogleMapsApiKey } from './utils/constants';
import { loadFonts } from './utils/loadFonts';

function updateCurrentLanguage() {
  const currentLang = i18n.language;
  moment.locale(currentLang);
}

// Listen for language changes
i18n.on('languageChanged', (lang) => {
  updateCurrentLanguage();
});


class App extends Component {
  render() {
    const gKey = getGoogleMapsApiKey();
    loadFonts();
    return (
      <Suspense fallback={<Spinner />}>
        <GoogleMapsLoader apiKey={gKey}>
          <APIProvider apiKey={gKey} language='en'>
            <Router />
          </APIProvider>
        </GoogleMapsLoader>
      </Suspense>
    );
  }
}

export default App;