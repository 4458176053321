import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend) // loads translations from the backend (your /locales directory)
    .use(LanguageDetector) // detects user language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en', // use en if detected lng is not available
        debug: false,
        // detection: {
        //     order: ['queryString', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        // },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // path to load the translations
        },
        ns: ['common', 'booking', 'dispatch'], // namespaces used in your application
        defaultNS: 'common',
        lng: 'en', // language to use
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;