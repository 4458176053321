import { combineReducers, Reducer, CombinedState } from 'redux';
import theme, { SettingsState } from './themeStore';
import user, { UserSettings } from './userStore';

export interface RootState {
  theme: SettingsState;
  user: UserSettings;
  // Add other state slices if available
}

const rootReducer: Reducer<CombinedState<RootState>> = combineReducers<RootState>({
  theme,
  user,
  // Add other reducers if available
});

export default rootReducer;