import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import '../../vendor/libs/react-toastify/react-toastify.scss'
import { getDomain } from '../../utils/constants';

class LayoutBlank extends Component {
  render() {
    const domain = getDomain();
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    return (
      <div className={`layout-blank ${domain}`}>
        {this.props.children}
        <ToastContainer
          autoClose={ '5000'}
          newestOnTop={true}
          //closeButton={<CloseButton />}
          rtl={isRTL}
        />
      </div>
    )
  }
}

export default LayoutBlank
