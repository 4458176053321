// export const DriverAcceptanceStatuses = {
//     0: {
//         id: 0,
//         enum: 'NotSet',
//         text: 'Not Set'
//     },
//     1: {
//         id: 1,
//         enum: 'Pending',
//         text: 'Pending'
//     },
//     2: {
//         id: 2,
//         enum: 'Declined',
//         text: 'Declined'
//     },
//     3: {
//         id: 3,
//         enum: 'Accepted',
//         text: 'Accepted'
//     }
// };

import store from "../store";

// export const DriverOnRouteStatuses = {
//     0: {
//         id: 0,
//         enum: 'NotSet',
//         text: 'Not Set'
//     },
//     1: {
//         id: 1,
//         enum: 'NotStarted',
//         text: 'Not started'
//     },
//     2: {
//         id: 2,
//         enum: 'OnMyWay',
//         text: 'Driver On Route'
//     },
//     3: {
//         id: 3,
//         enum: 'WaitingForPassenger',
//         text: 'Waiting For Passenger'
//     },
//     4: {
//         id: 4,
//         enum: 'PassengerOnBoad',
//         text: 'Passenger On Boad'
//     },
//     5: {
//         id: 5,
//         enum: 'DroppedPassenger',
//         text: 'Dropped Passenger'
//     },
//     6: {
//         id: 6,
//         enum: 'PassengerNoShow',
//         text: 'Passenger No Show'
//     },
//     7: {
//         id: 7,
//         enum: 'MissedBooking',
//         text: 'Missed Booking'
//     }
// }

type Status = {
    id: number;
    enum: string;
    textKey: string;
};

type StatusMap = {
    [key: number]: Status;
};

export const DriverAcceptanceStatuses: StatusMap = {
    0: { id: 0, enum: 'NotSet', textKey: 'dispatch:updateBookingStatus.status.driverAcceptanceStatuses.notSet' },
    1: { id: 1, enum: 'Pending', textKey: 'dispatch:updateBookingStatus.status.driverAcceptanceStatuses.pending' },
    2: { id: 2, enum: 'Declined', textKey: 'dispatch:updateBookingStatus.status.driverAcceptanceStatuses.declined' },
    3: { id: 3, enum: 'Accepted', textKey: 'dispatch:updateBookingStatus.status.driverAcceptanceStatuses.accepted' }
};

export const DriverOnRouteStatuses: StatusMap = {
    0: { id: 0, enum: 'NotSet', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.notSet' },
    1: { id: 1, enum: 'NotStarted', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.notStarted' },
    2: { id: 2, enum: 'OnMyWay', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.onMyWay' },
    3: { id: 3, enum: 'WaitingForPassenger', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.waitingForPassenger' },
    4: { id: 4, enum: 'PassengerOnBoad', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.passengerOnBoard' },
    5: { id: 5, enum: 'DroppedPassenger', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.droppedPassenger' },
    6: { id: 6, enum: 'PassengerNoShow', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.passengerNoShow' },
    7: { id: 7, enum: 'MissedBooking', textKey: 'dispatch:updateBookingStatus.status.driverOnRouteStatuses.missedBooking' }
};

export function getEnvironment(): "development" | "production" {
    return process.env.NODE_ENV === "development" ? "development" : "production";
}

export function getDomain(): "goldsainte" | "travelthru" | "default" {
    if (window.location.hostname.indexOf("goldsainte") > -1) return "goldsainte";
    //return "travelthru";
    if (window.location.hostname.indexOf("travelthru") > -1) return "travelthru";
    return "default";
}

export function isGoldsainte() {
    //return true;
    return window.location.hostname.indexOf("goldsainte") > -1;
}

export function getGoogleMapsApiKey() {
    var currentKey = store.getState().user.gKey;
    if (currentKey) return currentKey;
    return process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyCZ7fCpCAXWqIgdkaUXh6tLj3IBbRc0E10";
}

export const GoldsainteWebsiteGuid = "ee2583a5-14d0-422b-a435-414d37066bff";
export const TravelThruWebsiteGuid = "92e017f9-3c31-4045-bb60-d1d0f3615e33";

export const BookingPaymentStatus = {
    NotSet: 0,
    Pending: 1,
    Declined: 2,
    Complete: 3,
    PayLater: 4,
    PartiallyComplete: 5,
    Overpaid: 6,
    ToCapture: 7
}

export const PayoutTypes = {
    NotSet: 0,
    Driver: 1,
    Supplier: 2,
    CarOwner: 3,
    All: 4
}

export const CarReviewStatus = {
    NeedReview: 0,
    Available: 1,
    Rejected: 2
}

export const RoadMapConfig = {
    isHorizontal: false,
    bookingPageUrl: "/booking",
    locale: "en",
    fonts: {
      default: {
        family: "DM Sans",
        source:
          "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap",
      },
      heading: {
        family: "DegularDisplay-Semibold",
        source:
          "https://static.travelthru.com/css?font=degular-complete/degular-display/web/DegularDisplay-Semibold.woff2",
      },
    },
    colors: {
      primary: "#000000",
      secondary: {
        100: "#F24F1D",
        200: "#FFB709",
        300: "#FEF5E9",
      },
      third: {
        100: "#6F6F6F",
        200: "#7D7D7D",
        300: "#888888",
        400: "#D9D9D9",
        500: "#F3F3F3",
      },
    },
  };