import React, { useEffect, useState, ReactNode } from 'react';

interface GoogleMapsLoaderProps {
    children: ReactNode;
    apiKey: string;
}

declare global {
    interface Window {
        google?: {
            maps: any;
        };
    }
}

const isGoogleMapsLoaded = (): boolean => {
    return typeof window.google === 'object' && typeof window.google.maps === 'object';
};

const GoogleMapsLoader: React.FC<GoogleMapsLoaderProps> = ({ children, apiKey }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(isGoogleMapsLoaded());

    useEffect(() => {
        if (!isLoaded) {
            //console.log("GoogleMapsLoader loading script");
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry,drawing,places&language=en`;
            script.async = true;
            script.defer = true;
            script.onload = () => setIsLoaded(true);
            document.head.appendChild(script);

            return () => {
                // Cleanup function to remove the script if the component unmounts before loading
                document.head.removeChild(script);
            };
        }
    }, [isLoaded, apiKey]);

    //console.log("GoogleMapsLoader isLoaded:", isLoaded, apiKey);
    return isLoaded ? <>{children}</> : null;
};

export default GoogleMapsLoader;