export interface SettingsState {
  navbarBg: string;
  sidenavBg: string;
  footerBg: string;
  navigateToLink: string | null;
}

const getSettings = (): SettingsState => ({
  navbarBg: 'navbar-theme',
  sidenavBg: 'sidenav-theme',
  footerBg: 'footer-theme',
  navigateToLink: null,
});

const initialState: SettingsState = getSettings();

type Action = {
  type: string;
  data?: string;
};

function reducer(state: SettingsState = initialState, action: Action): SettingsState {
  switch (action.type) {
    case 'redirect': {
      return {
        ...state,
        navigateToLink: action.data ? action.data : null,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;