import React, { Component } from 'react'
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'
import layoutHelpers from './helpers'
import { ToastContainer } from 'react-toastify'
import '../../vendor/libs/react-toastify/react-toastify.scss'
import { getDomain } from '../../utils/constants'

class Layout2 extends Component {
  constructor(props) {
    super(props);
  }
  
  closeSidenav(e) {
    e.preventDefault()
    layoutHelpers.setCollapsed(true)
  }

  componentDidMount() {
    layoutHelpers.init()
    layoutHelpers.update()
    layoutHelpers.setAutoUpdate(true)
  }

  componentWillUnmount() {
    layoutHelpers.destroy()
  }

  render() {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    const domain = getDomain();
    return (
      <div className={`layout-wrapper layout-2 cbs-dispatch ${domain}`}>
        <div className="layout-inner" style={{overflowX: 'hidden'}}>
          <LayoutSidenav {...this.props} />

          <div className="layout-container">
            <LayoutNavbar {...this.props} title={this.props.title}/>

            <div className="layout-content">
              <div className="flex-grow-1" style={{background: 'white'}}>
                {this.props.children}
              </div>

              {/* <LayoutFooter {...this.props} /> */}
              <ToastContainer
                autoClose={'5000'}
                newestOnTop={true}
                //closeButton={<CloseButton />}
                rtl={isRTL}
              />
            </div>
          </div>
        </div>
        <div className="layout-overlay" onClick={this.closeSidenav}></div>
      </div>
    )
  }
}

export default Layout2
