import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidenav from "../../vendor/libs/sidenav";
import layoutHelpers from "./helpers";
import { hasAnyRole, hasRole } from "../../utils";
import { GoldsainteWebsiteGuid } from "../../utils/constants";
import { getDomainInfo } from "../../utils/userAccess";
import { withTranslation } from "react-i18next";

class LayoutSidenav extends Component {
  translate(key) {
    return this.props.t(key);
  }

  layoutSidenavClasses() {
    let bg = this.props.sidenavBg;

    if (this.props.orientation === "horizontal" && (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1)) {
      bg = bg.replace(" sidenav-dark", "").replace(" sidenav-light", "").replace("-darker", "").replace("-dark", "");
    }

    return `bg-${bg} ` + (this.props.orientation !== "horizontal" ? "layout-sidenav" : "layout-sidenav-horizontal container-p-x flex-grow-0");
  }

  toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  isMenuActive(singleOrMultipleUrls) {
    if (Array.isArray(singleOrMultipleUrls)) {
      const foundIndex = singleOrMultipleUrls.findIndex((el) => this.props.location.pathname.indexOf(el) === 0);
      return foundIndex >= 0;
    } else {
      return this.props.location.pathname.indexOf(singleOrMultipleUrls) === 0;
    }
  }

  isMenuOpen(singleOrMultipleUrls) {
    if (this.props.orientation === "horizontal") return false;

    if (Array.isArray(singleOrMultipleUrls)) {
      const foundIndex = singleOrMultipleUrls.findIndex((el) => this.props.location.pathname.indexOf(el) === 0);
      return foundIndex >= 0;
    } else {
      return this.props.location.pathname.indexOf(singleOrMultipleUrls) === 0 && this.props.orientation !== "horizontal";
    }
  }

  render() {
    const domainInfo = getDomainInfo(this.props);
    const selectedWebsite = domainInfo.selectedWebsite;
    const defaultWebsiteName = domainInfo.defaultWebsiteName;
    const isGoldsainte = domainInfo.isGoldsainte;
    var isTravelThru = domainInfo.isTravelThru;
    const isCarOwner = hasRole(domainInfo.selectedWebsite, "CarOwner");
    const isClient = hasRole(domainInfo.selectedWebsite, "Client");
    const isDriver = hasRole(domainInfo.selectedWebsite, "Driver");

    return (
      <Sidenav orientation={this.props.orientation} className={`${this.layoutSidenavClasses()} fixed-sidenav small-scale`} style={{ boxShadow: "0px 2px 16px 0px #1a1a1a3d" }}>
        {/* Brand demo (see src/demo.css) */}
        {this.props.orientation !== "horizontal" && (
          <React.Fragment>
            <div className="app-brand demo">
              <img alt="chauffeur booking software" className="app-brand-logo demo" style={{ backgroundColor: "white" }} src="app-icon.png" height={30} width={30}></img>
              <Link to="/" className="app-brand-text demo sidenav-text font-weight-normal ml-2">
                {defaultWebsiteName}
              </Link>
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}
        {/* Inner */}
        <div className={`sidenav-inner ${this.props.orientation !== "horizontal" ? "py-1" : ""}`}>
          {isCarOwner && (
            <>
              <Sidenav.RouterLink to="/rentals" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.rentals.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/vehicles" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.myVehicles.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/payments" exact={true} icon="ion ion-ios-wallet">
                {this.props.t("common:sidenav.payments.menu")}
              </Sidenav.RouterLink>
            </>
          )}

          {isClient && (
            <>
              <Sidenav.RouterLink to="/book" exact={true} icon="ion ion-ios-add">
                {this.props.t("common:sidenav.newBooking.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/bookings" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.bookings.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/my-profile" exact={true} icon="ion ion-ios-person">
                {this.props.t("common:sidenav.profile.menu")}
              </Sidenav.RouterLink>
            </>
          )}

          {isDriver && (
            <>
              <Sidenav.RouterLink to="/driver-bookings" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.bookings.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/my-profile" exact={true} icon="ion ion-ios-person">
                {this.props.t("common:sidenav.profile.menu")}
              </Sidenav.RouterLink>
            </>
          )}

          {hasAnyRole(selectedWebsite, ["Administrator", "Dispatcher"]) && (
            <Sidenav.RouterLink to="/booking" exact={true} icon="ion ion-ios-add">
              {this.props.t("common:sidenav.newBooking.menu")}
            </Sidenav.RouterLink>
          )}

          {hasAnyRole(selectedWebsite, ["Administrator", "Dispatcher"]) &&
            (isTravelThru && hasRole(selectedWebsite, "Administrator") ? (
              <Sidenav.Menu icon="ion ion-ios-list" linkText={this.props.t("common:sidenav.dispatch.menu")} active={this.isMenuActive(["/overview", "/dispatch"])} open={this.isMenuOpen(["/overview", "/dispatch"])}>
                <Sidenav.RouterLink to="/overview" exact={true} icon="ion ion-ios-stats">
                  {this.props.t("common:sidenav.dispatch.overview.menu")}
                </Sidenav.RouterLink>
                <Sidenav.RouterLink to="/dispatch" exact={true} icon="ion ion-ios-clipboard">
                  {this.props.t("common:sidenav.dispatch.menu")}
                </Sidenav.RouterLink>
              </Sidenav.Menu>
            ) : (
              <Sidenav.RouterLink to="/dispatch" exact={true} icon="ion ion-ios-list">
                {this.props.t("common:sidenav.dispatch.menu")}
              </Sidenav.RouterLink>
            ))}
          {isGoldsainte && hasAnyRole(selectedWebsite, ["Administrator", "Dispatcher"]) && (
            <Sidenav.RouterLink to="/rentals" exact={true} icon="ion ion-ios-car">
              {this.props.t("common:sidenav.rentals.menu")}
            </Sidenav.RouterLink>
          )}
          {hasAnyRole(selectedWebsite, ["Administrator", "Dispatcher"]) && (
            <Sidenav.RouterLink to="/live-map" exact={true} icon="ion ion-ios-map">
              {this.props.t("common:sidenav.liveMap.menu")}
            </Sidenav.RouterLink>
          )}
          {hasRole(selectedWebsite, "Administrator") && (
            <Sidenav.Menu icon="ion ion-ios-git-network" linkText={this.props.t("common:sidenav.partners.menu")} active={this.isMenuActive(["/suppliers", "/drivers"])} open={this.isMenuOpen(["/suppliers", "/drivers"])}>
              <Sidenav.RouterLink to="/drivers" exact={true} icon="ion ion-ios-person">
                {this.props.t("common:sidenav.driver.menu")}
              </Sidenav.RouterLink>

              <Sidenav.RouterLink to="/suppliers" exact={true} icon="ion ion-ios-business">
                {this.props.t('common:sidenav.suppliers.menu')}
              </Sidenav.RouterLink>
            </Sidenav.Menu>
          )}

          {hasRole(selectedWebsite, "Administrator") && (
            <Sidenav.RouterLink to="/clients" exact={true} icon="ion ion-ios-people">
              {this.props.t("common:sidenav.clients.menu")}
            </Sidenav.RouterLink>
          )}
          {hasRole(selectedWebsite, "Administrator") && (
            <Sidenav.RouterLink to="/coupons" exact={true} icon="ion ion-ios-cash">
              {this.props.t("common:sidenav.coupons.menu")}
            </Sidenav.RouterLink>
          )}

          {hasAnyRole(selectedWebsite, ["Administrator", "Dispatcher"]) && isGoldsainte && (
            <Sidenav.Menu icon="ion ion-ios-clipboard" linkText={this.props.t("common:sidenav.goldsainte.menu")} active={this.isMenuActive(["/carowners", "/vehicles", "/vehicles-types"])} open={this.isMenuOpen(["/carowners", "/vehicles", "/vehicles-types"])}>
              <Sidenav.RouterLink to="/carowners" exact={true} icon="ion ion-ios-people">
                {this.props.t("common:sidenav.carOwners.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/vehicles" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.carShare.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/vehicles-types" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.vehiclesTypes.menu")}
              </Sidenav.RouterLink>
            </Sidenav.Menu>
          )}

          {hasRole(selectedWebsite, "Administrator") && (
            <Sidenav.Menu icon="ion ion-ios-clipboard" linkText={this.props.t("common:sidenav.reports.menu")} active={this.isMenuActive(["/reports", "/payouts-outstanding", "/payments"])} open={this.isMenuOpen(["/reports", "/payouts-outstanding", "/payments"])}>
              <Sidenav.RouterLink to="/reports-bookings" exact={true} icon="ion ion-ios-stats">
                {this.props.t("common:sidenav.reports.bookings.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/payments" exact={true} icon="ion ion-ios-wallet">
                {this.props.t("common:sidenav.reports.payments.menu")}
              </Sidenav.RouterLink>
              {!isTravelThru && this.props.isBookingDotCom && (
                <Sidenav.RouterLink to="/operations" exact={true} icon="ion ion-ios-stats">
                  {this.props.t("common:sidenav.reports.operations.menu")}
                </Sidenav.RouterLink>
              )}
            </Sidenav.Menu>
          )}

          {hasRole(selectedWebsite, "Administrator") && this.props.isBookingDotCom && (
            <Sidenav.Menu icon="ion ion-md-speedometer" linkText={this.props.t("common:sidenav.bookingCom.menu")} active={this.isMenuActive("/bookingdotcom")} open={this.isMenuOpen("/bookingdotcom")}>
              <Sidenav.RouterLink to="/bookingdotcom-requests" exact={true} icon="ion ion-ios-list">
                {this.props.t("common:sidenav.bookingCom.requests.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/bookingdotcom-stats" exact={true} icon="ion ion-ios-stats">
                {this.props.t("common:sidenav.bookingCom.statistics.menu")}
              </Sidenav.RouterLink>
            </Sidenav.Menu>
          )}

          {(hasRole(selectedWebsite, "Administrator") && !domainInfo.isProduction && this.props.isBookingDotCom) && (
            <Sidenav.Menu icon="ion ion-logo-usd" linkText={this.props.t("common:sidenav.pricing.menu")} active={this.isMenuActive(["/edit-prices", "/services"])} open={this.isMenuOpen(["/edit-prices", "/services"])}>
              <Sidenav.RouterLink to={`/edit-prices?website=${selectedWebsite.websiteGuid}`} exact={true} icon="ion ion-ios-settings">
                {this.props.t("common:sidenav.pricing.editPrices.menu")}
              </Sidenav.RouterLink>
              <Sidenav.RouterLink to="/services" exact={true} icon="ion ion-ios-car">
                {this.props.t("common:sidenav.pricing.services.menu")}
              </Sidenav.RouterLink>
            </Sidenav.Menu>
          )}

          {this.props.isSuperAdmin && (
            <Sidenav.Menu icon="ion ion-ios-settings" linkText={this.props.t("common:sidenav.admin.menu")} active={this.isMenuActive(["/admin-settings", "/admin/websites", "/admin/users"])} open={this.isMenuOpen(["/settings", "/admin/websites", "/admin/users"])}>
              <Sidenav.RouterLink to="/admin-settings" exact={true} icon="ion ion-ios-cog">
                {this.props.t("common:sidenav.cache.menu")}
              </Sidenav.RouterLink>
            </Sidenav.Menu>
          )}
        </div>
      </Sidenav>
    );
  }
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

LayoutSidenav.defaultProps = {
  orientation: "vertical",
};

export default withTranslation()(
  connect((store) => ({
    sidenavBg: store.theme.sidenavBg,
    websites: store.user.websites,
    isBookingDotCom: store.user.isBookingDotCom,
    isSuperAdmin: store.user.isSuperAdmin,
  }))(LayoutSidenav)
);