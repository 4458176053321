export const loadFonts = () => {
  const fonts = [
    { name: 'DegularDisplay-Semibold', path: `${process.env.PUBLIC_URL}/travelthru/fonts/DegularDisplay-Semibold.woff` },
    { name: 'DegularDisplay-BlackItalic', path: `${process.env.PUBLIC_URL}/travelthru/fonts/DegularDisplay-BlackItalic.woff2` },
    { name: 'DegularDisplay-BoldItalic', path: `${process.env.PUBLIC_URL}/travelthru/fonts/DegularDisplay-BoldItalic.woff` },
    { name: 'DegularDisplay-Medium', path: `${process.env.PUBLIC_URL}/travelthru/fonts/DegularDisplay-Medium.woff` },
    { name: 'DegularDisplay-MediumItalic', path: `${process.env.PUBLIC_URL}/travelthru/fonts/DegularDisplay-MediumItalic.woff` },
    { name: 'DegularDisplay-Regular', path: `${process.env.PUBLIC_URL}/travelthru/fonts/DegularDisplay-Regular.woff` },
  ];

  fonts.forEach(font => {
    const fontFace = new FontFace(font.name, `url(${font.path})`);
    fontFace.load().then(loadedFont => {
      document.fonts.add(loadedFont);
    });
  });
};