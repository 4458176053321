import store from "../../../store";
import { Redirect, Route } from "react-router-dom";
import { hasRole, hasAnyRole } from "../../../utils";

export function PrivateRoute({ children, ...rest }) {
  //console.log("Children: ", children)
  //console.log("Children: ", rest)
  const state = store.getState();
  const isAuth = state.user.token;

  //TODO: enhance route with roles
  const selectedWebsite = state?.user?.websites ? state?.user?.websites.find(w => w.isDefault) : null;
  const isAdminOrDispatcher = hasAnyRole(selectedWebsite, ["Administrator", "Dispatcher"]);
  const isClient = hasRole(selectedWebsite, "Client");
  const isDriver = hasRole(selectedWebsite, "Driver");
  const isCarOwner = hasRole(selectedWebsite, "CarOwner");

  if (selectedWebsite != null && !isAdminOrDispatcher && !isClient && !isDriver && !isCarOwner) {
    console.log("Redirect to login: ");
    return <Redirect
      to={{ pathname: "/login" }}
    />
  }

  if (!isAuth) {
    return <Redirect
      to={{
        pathname: "/login",
        //state: { from: location }
      }}
    />
  }
  return (
    <Route {...rest} />
  );
}